import { Images } from './Images';

export const navItems = [
  { title: 'NAVIGATION.HOME', name: 'home', icon: 'home', navLink: '/home' },
  {
    title: 'NAVIGATION.RELEASE-CYCLES',
    name: 'release-cycles?view=day',
    icon: 'calendar',
    navLink: '/release-cycles',
    isBeta: true,
  },
  {
    title: 'NAVIGATION.PLAYLIST-PITCHING',
    name: 'playlisting',
    icon: 'playlistPitch',
    navLink: '/playlisting',
  },
  {
    title: 'NAVIGATION.SOCIAL-ADS',
    name: 'social-ads',
    icon: 'megaphone',
    navLink: '/social-ads',
  },
  {
    title: 'NAVIGATION.INSIGHTS',
    name: 'insights',
    icon: 'budget',
    navLink: '/insights',
  },
  {
    title: 'NAVIGATION.FAN-HUB',
    name: 'fan-hub',
    icon: 'website',
    navLink: '/fan-hub',
    isPro: true,
  },
  {
    title: 'NAVIGATION.PARTNER-PERKS',
    name: 'partner-perks',
    icon: 'star',
    navLink: '/partner-perks',
  },
  // {
  //   title: 'NAVIGATION.SETTINGS',
  //   name: 'settings',
  //   icon: 'settings',
  //   navLink: '/settings',
  // },
];

export const navItemsExpanded = [
  { title: 'NAVIGATION.HOME', name: 'home', icon: Images.widgetImages.home, navLink: '/home' },
  {
    title: 'NAVIGATION.RELEASE-CYCLES',
    name: 'release-cycles',
    icon: Images.widgetImages.releaseCycles,
    navLink: '/release-cycles',
    notifications: true,
  },
  {
    title: 'NAVIGATION.PLAYLIST-PITCHING',
    name: 'playlisting',
    icon: Images.widgetImages.playlistPitches,
    navLink: '/playlisting',
    notifications: true,
  },
  {
    title: 'NAVIGATION.SOCIAL-ADS',
    name: 'social-ads',
    icon: Images.widgetImages.socialAds,
    navLink: '/social-ads',
    notifications: true,
  },
  {
    title: 'NAVIGATION.INSIGHTS',
    name: 'insights',
    icon: Images.widgetImages.insights,
    navLink: '/insights',
  },
  {
    title: 'NAVIGATION.FAN-HUB',
    name: 'fan-hub',
    icon: Images.widgetImages.fanHub,
    navLink: '/fan-hub',
  },
  {
    title: 'NAVIGATION.PARTNER-PERKS',
    name: 'partner-perks',
    icon: Images.widgetImages.partnerPerks,
    navLink: '/partner-perks',
  },
  {
    title: 'NAVIGATION.PROFILE',
    name: 'profile',
    icon: Images.widgetImages.profile,
    navLink: '/profile',
  },
  {
    title: 'NAVIGATION.SETTINGS',
    name: 'settings',
    icon: Images.widgetImages.settings,
    navLink: '/settings',
  },
  {
    title: 'NAVIGATION.SUPPORT',
    name: 'support',
    icon: Images.widgetImages.support,
    navLink: '/settings',
  },
];
