import { Flags, WalkthroughConfig } from '@/models/Walkthrough';

export const homeWalkthrough: WalkthroughConfig = {
  id: 'home',
  startRoute: '/home',
  startModal: function (flags: Flags) {
    let startModal = {
      imageUrl: 'https://cdn.unhurd.co.uk/images/walkthrough-home-returning-user.png',
      title: 'WALKTHROUGH.WALKTHROUGHS.HOME.START.TITLE-RETURNING',
      content: 'WALKTHROUGH.WALKTHROUGHS.HOME.START.CONTENT-RETURNING',
      nextText: 'WALKTHROUGH.WALKTHROUGHS.HOME.START.NEXT',
      skipText: 'WALKTHROUGH.WALKTHROUGHS.HOME.START.SKIP',
    };
    if (flags.isNewAccount) {
      startModal = {
        ...startModal,
        imageUrl: 'https://cdn.unhurd.co.uk/images/walkthrough-home-new-user.png',
        title: 'WALKTHROUGH.WALKTHROUGHS.HOME.START.TITLE-NEW',
        content: 'WALKTHROUGH.WALKTHROUGHS.HOME.START.CONTENT-NEW',
      };
    }
    return startModal;
  },
  steps: [
    function (flags: Flags) {
      let content = 'WALKTHROUGH.WALKTHROUGHS.HOME.YOUR-RELEASES.CONTENT-RETURNING';
      let selector = '#home-screen-release-cycles';
      let spotlightConfig = {};
      if (flags.activeReleaseCycle) {
        content = 'WALKTHROUGH.WALKTHROUGHS.HOME.YOUR-RELEASES.CONTENT-NEW';
        selector = '#home-screen-release-cycles';
        spotlightConfig = {
          padding: {
            right: 12,
          },
        };
      }
      return {
        placement: 'bottom',
        target: selector,
        title: 'WALKTHROUGH.WALKTHROUGHS.HOME.YOUR-RELEASES.TITLE',
        content: content,
        spotlightConfig: spotlightConfig,
      };
    },
    function (flags: Flags) {
      let content = 'WALKTHROUGH.WALKTHROUGHS.HOME.YOUR-NAVIGATION.CONTENT-RETURNING';
      if (flags.isNewAccount) {
        content = 'WALKTHROUGH.WALKTHROUGHS.HOME.YOUR-NAVIGATION.CONTENT-NEW';
      }
      return {
        placement: 'right',
        target: '#nav-container',
        title: 'WALKTHROUGH.WALKTHROUGHS.HOME.YOUR-NAVIGATION.TITLE',
        content: content,
        spotlightConfig: {
          padding: {
            right: 12,
          },
        },
      };
    },
    function (flags: Flags) {
      let content = 'WALKTHROUGH.WALKTHROUGHS.HOME.PLAYLIST-PITCHING.CONTENT-RETURNING';
      if (flags.isNewAccount) {
        content = 'WALKTHROUGH.WALKTHROUGHS.HOME.PLAYLIST-PITCHING.CONTENT-NEW';
      }
      return {
        placement: 'right',
        target: '#nav-bar-item-playlisting',
        title: 'WALKTHROUGH.WALKTHROUGHS.HOME.PLAYLIST-PITCHING.TITLE',
        content: content,
        spotlightConfig: {
          padding: {
            right: 12,
          },
        },
      };
    },
    function (flags: Flags) {
      let content = 'WALKTHROUGH.WALKTHROUGHS.HOME.YOUR-QUICK-ACTIONS.CONTENT-RETURNING';
      if (flags.isNewAccount) {
        content = 'WALKTHROUGH.WALKTHROUGHS.HOME.YOUR-QUICK-ACTIONS.CONTENT-NEW';
      }
      return {
        placement: 'top',
        target: '#home-page-widgets',
        title: 'WALKTHROUGH.WALKTHROUGHS.HOME.YOUR-QUICK-ACTIONS.TITLE',
        content: content,
        spotlightConfig: {
          padding: {
            top: 52,
            left: 2,
            right: 2,
          },
        },
      };
    },
  ],
};

export const releaseCyclesWalkthrough: WalkthroughConfig = {
  id: 'releaseCycle',
  startRoute: '/release-cycles',
  startModal: {
    imageUrl: 'https://cdn.unhurd.co.uk/images/walkthrough-release-cycles.png',
    title: 'WALKTHROUGH.WALKTHROUGHS.RELEASE-CYCLES.START.TITLE',
    content: 'WALKTHROUGH.WALKTHROUGHS.RELEASE-CYCLES.START.CONTENT',
    nextText: 'WALKTHROUGH.WALKTHROUGHS.RELEASE-CYCLES.START.NEXT',
    skipText: 'WALKTHROUGH.WALKTHROUGHS.RELEASE-CYCLES.START.SKIP',
  },
  steps: [
    {
      placement: 'bottom',
      target: '#release-cycle-selector',
      title: 'WALKTHROUGH.WALKTHROUGHS.RELEASE-CYCLES.TOGGLE-RELEASES.TITLE',
      content: 'WALKTHROUGH.WALKTHROUGHS.RELEASE-CYCLES.TOGGLE-RELEASES.CONTENT',
    },
    {
      placement: 'right',
      target: '#release-cycle-calender',
      title: 'WALKTHROUGH.WALKTHROUGHS.RELEASE-CYCLES.CALENDAR.TITLE',
      content: 'WALKTHROUGH.WALKTHROUGHS.RELEASE-CYCLES.CALENDAR.CONTENT',
    },
    {
      placement: 'bottom',
      target: '#release-cycles-switch-view-button',
      title: 'WALKTHROUGH.WALKTHROUGHS.RELEASE-CYCLES.CHANGE-YOUR-VIEW.TITLE',
      content: 'WALKTHROUGH.WALKTHROUGHS.RELEASE-CYCLES.CHANGE-YOUR-VIEW.CONTENT',
    },
    {
      placement: 'bottom',
      target: '#release-cycle-daily-tasks',
      title: 'WALKTHROUGH.WALKTHROUGHS.RELEASE-CYCLES.TASK-CARDS.TITLE',
      content: 'WALKTHROUGH.WALKTHROUGHS.RELEASE-CYCLES.TASK-CARDS.CONTENT',
    },
  ],
};

export const RegisteredWalkthroughs: WalkthroughConfig[] = [homeWalkthrough, releaseCyclesWalkthrough];
