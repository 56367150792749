import { useNavigate, useSearchParams } from 'react-router-dom';

import { PromoteFlowQueryParams } from '@/models/Enums';

export const useActivityActionHandler = (url: string) => {
  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();

  const handleActivity = () => {
    if (url.includes('unhurd://unhurd.co.uk/')) {
      const route = url.split('unhurd://unhurd.co.uk/')[1];
      switch (route) {
        case 'fan-hub': {
          params.set(PromoteFlowQueryParams.FAN_HUB, 'true');
          setSearchParams(params);
          break;
        }
        case 'meta-ads': {
          params.set(PromoteFlowQueryParams.META_ADS, 'true');
          setSearchParams(params);
          break;
        }
        case 'tiktok-ads': {
          params.set(PromoteFlowQueryParams.TIKTOK_ADS, 'true');
          setSearchParams(params);
          break;
        }
        case 'playlisting': {
          params.set(PromoteFlowQueryParams.PLAYLISTING, 'true');
          setSearchParams(params);
          break;
        }
        case 'release-cycles': {
          params.set(PromoteFlowQueryParams.RELEASE_CYCLES, 'true');
          setSearchParams(params);
          break;
        }
        case 'insights': {
          navigate('/insights');
          break;
        }
      }
    } else {
      window.open(url);
    }
  };

  return {
    handleActivity: handleActivity,
  };
};
